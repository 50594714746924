<template>
	<div class="authPage">
		<!-- <div
			class="
				content-top-bar
				d-flex
				justify-content-between
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		></div>
		<div
			class="w-100"
			:class="{
				'pb-80': helpers.layout.isMobile(),
			}"
		>
			<div
				class="px-3 terms-of-use"
				:class="{
					'pt-40': !helpers.layout.isMobile(),
				}"
				v-html="thisData"
			></div>
			<div
				:class="{
					'position-fixed bottom-0 start-0 w-100': helpers.layout.isMobile(),
					'position-sticky bottom-0 pt-30': !helpers.layout.isMobile(),
				}"
			>
				<button type="submit" class="w-100 btn btn-primary" @click="agree">
					I Agree
				</button>
			</div>
		</div> -->

		<div
			class="
				content-top-bar
				d-flex
				justify-content-between
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		></div>
		<div
			:class="{
				'pb-86': helpers.layout.isMobile(),
				'height-minus-top-bar': !helpers.layout.isMobile(),
			}"
		>
			<div class="w-100">
				<div class="p-3">
					<!-- <div class="terms-of-use" v-html="thisData"></div> -->
					<div
						class="span12 widget-span widget-type-custom_widget dnd-module"
						style=""
						data-widget-type="custom_widget"
						data-x="0"
						data-w="12"
					>
						<div
							id="hs_cos_wrapper_widget_1638942296699"
							class="
								hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module
								widget-type-rich_text
							"
							style=""
							data-hs-cos-general-type="widget"
							data-hs-cos-type="module"
						>
							<span
								id="hs_cos_wrapper_widget_1638942296699_"
								class="
									hs_cos_wrapper
									hs_cos_wrapper_widget
									hs_cos_wrapper_type_rich_text
								"
								style=""
								data-hs-cos-general-type="widget"
								data-hs-cos-type="rich_text"
								><h1><span>TERMS OF USE </span></h1>
								<p><span>Version date: 1 May 2020</span></p>
								<p><span role="presentation" dir="ltr">&nbsp;</span></p>
								<div aria-label="Page 1" data-page-number="1">
									<div>
										<span role="presentation" dir="ltr"
											>PLEASE READ THE TERMS AS HEREAFTER SET FORTH (“TERMS OF
											USE”) </span
										><span role="presentation" dir="ltr">CAREFULLY</span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr">BEFORE</span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr">INSTALLING</span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr">OR</span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr">USING</span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"> </span
										><span role="presentation" dir="ltr"
											><a href="http://www.cognalearn.com" rel="noopener"
												>www.cognalearn.com</a
											>,</span
										><br role="presentation" /><span
											role="presentation"
											dir="ltr"
											><a href="/?hsLang=en" rel="noopener"
												>www.intedashboard.com</a
											>,
											<a href="http://exam.intelearner.com" rel="noopener"
												>exam.intelearner.com</a
											>,
											<a href="http://game.intelearner.com" rel="noopener"
												>game.intelearner.com</a
											>
											and </span
										><span role="presentation" dir="ltr"
											><a href="http://www.5stepcareers.com" rel="noopener"
												>www.5stepcareers.com</a
											>
											(the “Websites”) or the Platform (defined below) </span
										><span role="presentation" dir="ltr"
											>(collectively, the Websites and Platform shall be
											referred to as the “Services”).</span
										>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>By using any of the Services, whether as an Institutional
											User or as an Individual </span
										><span role="presentation" dir="ltr"
											>User, you agree to be bound by the terms of these Terms
											of Use.
										</span>
									</div>
									<div><span role="presentation" dir="ltr">&nbsp;</span></div>
									<div>
										<span role="presentation" dir="ltr"
											>This Terms of Use is made between CognaLearn Pte Ltd, and
											its affiliates </span
										><span role="presentation" dir="ltr"
											>(“CognaLearn”, “us” or “we” as the case may be) and you
											with respect to your </span
										><span role="presentation" dir="ltr"
											>use of the Services and any functionalities, services or
											features offered via or in </span
										><span role="presentation" dir="ltr"
											>connection with the Services, and references in these
											Terms of Use to the use of </span
										><span role="presentation" dir="ltr"
											>the Services extend to the use of such functionalities,
											services or features as </span
										><span role="presentation" dir="ltr"
											>well. You are not eligible to use the Services if you are
											under 18 years of age </span
										><span role="presentation" dir="ltr"
											>unless you have obtained our consent and the consent of
											your parents or legal </span
										><span role="presentation" dir="ltr"
											>guardians, and your parents or legal guardians have
											agreed to these Terms of </span
										><span role="presentation" dir="ltr"
											>Use or unless permitted by applicable law. For example,
											in Singapore, the Civil </span
										><span role="presentation" dir="ltr"
											>Law Act states that individuals under the age of 18
											cannot enter into legally </span
										><span role="presentation" dir="ltr"
											>binding contracts however there exist common law
											exceptions relating to </span
										><span role="presentation" dir="ltr"
											>entering into contracts necessary for the education of
											the minor.</span
										>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>IF YOU ARE A RESIDENT OF THE EUROPEAN UNION you are not
											allowed to use </span
										><span role="presentation" dir="ltr"
											>any of the Services unless you are doing so on a basis
											which does not involve </span
										><span role="presentation" dir="ltr"
											>use of your personal data or by specific consent by us to
											ensure that you and us </span
										><span role="presentation" dir="ltr"
											>are fully compliant with all applicable European Union
											General Data Protection </span
										><span role="presentation" dir="ltr">Regulations.</span>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>From time to time, CognaLearn may change, remove, or add
											to the terms of </span
										><span role="presentation" dir="ltr"
											>these Terms of Use by posting amendments at the following
											URL: </span
										><br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>www.cognalearn.com or www.intedashboard.com/terms-of-use
											or whenever we </span
										><span role="presentation" dir="ltr"
											>post an update to the Services. By your continuing to use
											any of the Services </span
										><span role="presentation" dir="ltr"
											>after any such amendment, you agree to be bound by the
											Terms of Use as so </span
										><span role="presentation" dir="ltr">amended.</span>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT OR ARE
											INELIGIBLE </span
										><span role="presentation" dir="ltr"
											>TO USE THE SERVICES, PLEASE DO NOT USE THE
											SERVICES.</span
										>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>For the purposes of these Terms of Use, the following
											terms shall bear the </span
										><span role="presentation" dir="ltr"
											>following meanings:</span
										><br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>“Individual User” means any private individual who is
											using the Platform;</span
										>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>“Institution” means any school, college, university,
											non-profit organization, </span
										><span role="presentation" dir="ltr"
											>government or government agency, institution of higher
											education or </span
										><span role="presentation" dir="ltr">corporation;</span>
									</div>
									<div><span role="presentation" dir="ltr">&nbsp;</span></div>
									<div>
										&nbsp;“Institutional User” means any Institution that has
										obtained a licence to the Platform in accordance with the
										terms of these Terms of Use;
									</div>
									<div>&nbsp;</div>
								</div>
								<div aria-label="Page 2" data-page-number="2">
									<div>
										<span role="presentation" dir="ltr"
											>“Licence Fee” shall be the fee payable by Users to
											CognaLearn (where </span
										><span role="presentation" dir="ltr">applicable);</span>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>“Platform” means the educational mobile and/or web-based
											application, which </span
										><span role="presentation" dir="ltr"
											>may be updated from time to time and unless otherwise
											expressly stated in </span
										><span role="presentation" dir="ltr"
											>these Terms of Use, any reference in these Terms of Use
											to the Platform shall be </span
										><span role="presentation" dir="ltr"
											>taken to refer to the Platform as used by all
											Users;</span
										>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>“Professional Users” means any professionals or employees
											who have been </span
										><span role="presentation" dir="ltr"
											>granted a licence by an Institution to use the Platform
											in accordance with the </span
										><span role="presentation" dir="ltr"
											>terms of these Terms of Use; Professional Users are
											typically administrators, </span
										><span role="presentation" dir="ltr"
											>teachers, faculty and staff; and</span
										>
									</div>
									<div>
										<br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>“Users”</span
										><span role="presentation" dir="ltr"
											>means collectively the Individual Users, Professional
											Users and the </span
										><span role="presentation" dir="ltr"
											>Institutional Users.</span
										>
									</div>
								</div>
								<p>&nbsp;</p>
								<h2>
									<span role="presentation" dir="ltr"
										>1. <span>LICENSE GRANT</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											>Subject always to your continuing compliance with the
											terms of these </span
										><span role="presentation" dir="ltr"
											>Terms of Use and, where applicable, your payment of the
											Licence Fee, we </span
										><span role="presentation" dir="ltr"
											>grant you the following licence in respect of the
											Platform:</span
										><br /><br /><span role="presentation" dir="ltr"
											>a) If you are an Institutional User (unless otherwise
											separately agreed between us): a limited, worldwide,
											non-transferable, non-exclusive, sub-licensable (to the
											specified number of Professional and Individual Users
											only) licence to use the Platform on your device only for
											your own purposes or internal business or educational
											purposes, on and subject to the terms of these Terms of
											Use. All rights not expressly granted to you are reserved;
											you shall not use the Platform on devices which you do not
											own or control; you shall sub-licence the Platform to the
											specified number of Professional and Individual Users only
											in accordance with this licence grant. A sub-licence of
											the Platform by you to the Professional or Individual
											Users for any other purpose is strictly prohibited.</span
										><span role="presentation" dir="ltr"
											><br />b) If you are an Individual or Professional User, a
											limited, worldwide, non- transferable, non-exclusive,
											non-sublicensable licence to use the Platform on your
											device only for your own purposes or internal business or
											educational purposes, on and subject to the terms of<br
												role="presentation" />these Terms of Use. All rights not
											expressly granted to you are reserved.<br
										/></span>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>The licences granted herein do not confer on you any
											rights to use “CognaLearn”, “InteDashboard”, or any other
											logos, service marks,<br role="presentation" />slogans,
											product names and designations and other proprietary
											indicia used as part of the Services, whether registered
											or unregistered (collectively “Trade Marks”), all of which
											are and remain the property of CognaLearn or our licensors
											whether registered or not.</span
										>
									</li>
								</ol>
								<h2>2. <span>USER CONTENT &amp; PERMISSIONS </span></h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											>As a part of the Services, you may participate in, set up
											and contribute to </span
										><span role="presentation" dir="ltr"
											>discussions, publish material including readiness
											assurance tests, quizzes </span
										><span role="presentation" dir="ltr"
											>or applications, interact with or communicate with the
											other Users and </span
										><span role="presentation" dir="ltr"
											>post comments, opinions, and other content or
											communications, or other </span
										><span role="presentation" dir="ltr"
											>material (collectively, “User Content”). Your User
											Content belongs to you.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>You represent that you have all necessary right, power,
											and authority to </span
										><span role="presentation" dir="ltr"
											>use the Services and share User Content and will comply
											with all</span
										><br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>applicable laws when doing so.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>These Terms of Use do not give us any rights to User
											Content except for </span
										><span role="presentation" dir="ltr"
											>the rights that enable us to offer and improve the
											Services. We need your </span
										><span role="presentation" dir="ltr"
											>permission to do things like host User Content, back it
											up, or share it </span
										><span role="presentation" dir="ltr"
											>when you ask us to. This permission extends to our
											trusted third parties </span
										><span role="presentation" dir="ltr"
											>we work with. You therefore give us, and third parties
											(with whom we </span
										><span role="presentation" dir="ltr"
											>work) permission to use, display, host, share, and back
											up User Content. </span
										><span role="presentation" dir="ltr"
											>By submitting User Content, you also permit us to
											identify you by your </span
										><span role="presentation" dir="ltr"
											>username and as the contributor of the User
											Content.</span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										>3.
										<span
											>SHARING YOUR CONTENT AND USING THE SERVICES
										</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											>Our Services let you share User Content and interact with
											others, so please be thoughtful about what you share. By
											using the Services, you agree to do so responsibly and to
											not misuse the Services or help anyone else do so. This
											includes, but is not limited to, not doing or trying to do
											any of the following in connection with the Services
											(collectively, “Prohibited Uses”):<br /><br
												role="presentation"
											/>a) store, transmit or create libelous, obscene,
											deceptive, defamatory, pornographic, racist, sexual,
											hateful, unlawful, tortious or otherwise objectionable
											content (except as necessary for legitimate instructional
											purposes, but in all cases in compliance with
											applicable<br role="presentation" />laws and
											regulations);<br role="presentation" />b) modify,
											translate, adapt, merge, make derivative works of,
											disassemble, decompile, reverse compile or reverse
											engineer any part<br role="presentation" />of the Services
											except to the extent the foregoing restrictions are
											expressly prohibited by applicable law;<br
												role="presentation"
											/>c) harm, harass, threaten, or impersonate any person or
											violate the rights of any third party;<br
												role="presentation"
											/>d) probe, scan, or test the vulnerability of any system
											or network;<br role="presentation" />e) interfere with or
											disrupt the integrity or performance of the
											Services;</span
										><br />
										<div aria-label="Page 4" data-page-number="4">
											<div>
												<span role="presentation" dir="ltr">f)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>attempt to gain unauthorized access to the Services
													or its related </span
												><span role="presentation" dir="ltr"
													>systems or networks;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>g)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>introduce viruses, Trojan horses, worms, spyware, or
													other such </span
												><span role="presentation" dir="ltr"
													>malicious code into the Services;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>h)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>breach or otherwise circumvent any security or
													authentication </span
												><span role="presentation" dir="ltr">measures;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>i)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>access, tamper with, or use non-public areas or parts
													of the Services, </span
												><span role="presentation" dir="ltr"
													>or shared areas of the Services you haven't been
													invited to;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>j)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>access, search, or create accounts for the Services
													by any means </span
												><span role="presentation" dir="ltr"
													>other than our publicly supported interfaces (for
													example, "scraping" </span
												><span role="presentation" dir="ltr"
													>or creating accounts in bulk);</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>k)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>send unsolicited communications, promotions or
													advertisements, or </span
												><span role="presentation" dir="ltr">spam;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>l)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>send altered, deceptive or false source-identifying
													information, </span
												><span role="presentation" dir="ltr"
													>including "spoofing" or "phishing";</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>m)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>promote or advertise products or services other than
													your own </span
												><span role="presentation" dir="ltr"
													>without appropriate authorization;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>n)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>sell or re-sell the Services unless specifically
													authorized to do so;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>o)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>copy, reproduce, distribute, republish, download,
													display, post or </span
												><span role="presentation" dir="ltr"
													>transmit in any form or by any means any part of the
													Services unless</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>specifically authorized to do so;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>p)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>remove or destroy any copyright notices or other
													proprietary </span
												><span role="presentation" dir="ltr"
													>markings contained on or in the Services; </span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>q)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>violate the law in any way, including by storing,
													publishing or sharing </span
												><span role="presentation" dir="ltr"
													>material that is infringing, fraudulent, defamatory,
													misleading, or</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>damaging to CognaLearn in any way;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>r)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>use the Services with proprietary or confidential
													information because </span
												><span role="presentation" dir="ltr"
													>the Services may use transmissions over the Internet
													which are never </span
												><span role="presentation" dir="ltr"
													>100% completely private or secure;</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>s)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>use the Services is manner for which it is not
													intended or suitable </span
												><span role="presentation" dir="ltr"
													>such as situations or environments where the failure
													or time delays</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>of, or errors or inaccuracies in, the content, data
													or information </span
												><span role="presentation" dir="ltr"
													>provided by the Services or the Services Functions
													could lead to </span
												><span role="presentation" dir="ltr"
													>death, personal injury, or otherwise result in
													significant financial loss </span
												><span role="presentation" dir="ltr"
													>or business interruption;</span
												>
											</div>
											<div>
												&nbsp;<span role="presentation" dir="ltr">t)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>violate or encourage others to violate any right of
													or obligation to a </span
												>third party, including by infringing, misappropriating
												or violating
											</div>
										</div>
										<div aria-label="Page 5" data-page-number="5">
											<div>
												<span role="presentation" dir="ltr"
													>intellectual property, confidentiality, or privacy
													rights; or</span
												><br role="presentation" /><span
													role="presentation"
													dir="ltr"
													>u)</span
												><span role="presentation" dir="ltr"> </span
												><span role="presentation" dir="ltr"
													>use or attempt to use another User’s account or
													personal data</span
												>
											</div>
										</div>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										>4. <span>YOUR RESPONSIBILITY</span></span
									>
								</h2>
								<ol>
									<li>
										<div aria-label="Page 5" data-page-number="5">
											<span role="presentation" dir="ltr"
												>You will be required to register for an account in
												order to use the </span
											><span role="presentation" dir="ltr">Platform.</span>
										</div>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>You shall:<br role="presentation" />a) only provide
											accurate and current information about yourself;<br
												role="presentation"
											/>b) maintain the security of your passwords and
											identification;<br role="presentation" />c) promptly
											update the email address listed in connection with your
											account to keep it accurate so that we can contact you;
											and <br role="presentation" />d) be fully responsible for
											all uses of your account.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>You must not set up an account on behalf of another
											individual or entity unless you are expressly authorized
											by that other person or entity to do so.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>You agree and acknowledge that: it shall be your sole
											responsibility to, at your own cost: obtain all necessary
											hardware, software and<br
												role="presentation"
											/>communications services necessary for your use of the
											Services; install antivirus or other mobile security
											software to protect against any security or other
											vulnerabilities which may arise in connection with the use
											of the Services; and make back-ups of data or other
											content posted via the Services, as these may be
											subsequently deleted by us or our service providers at any
											time without notice to you.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>License fees<br role="presentation" />a) If you are an
											Institutional User, the licence fee payable by you to us
											shall be set out under a separate agreement to be entered
											into<br role="presentation" />between us.<br
												role="presentation"
											/>b) If you are an Individual User with a student payment
											model, the licence fee payable by you to us for the use of
											the Platform will be<br role="presentation" />communicated
											to you at the time for initial login.<br
												role="presentation"
											/>c) All payments by Users to us must be made in full
											without any withholding, deduction or set off whatsoever,
											via bank transfer or<br role="presentation" />cheque, or
											such other mode of payment as we may prescribe in writing
											from time to time at its sole and absolute discretion.
											Where<br role="presentation" />you are required by law to
											make any deductions or withholding from any sum payable by
											you to us, you shall forthwith pay to us such<br
												role="presentation"
											/>additional amount or amounts so as to ensure that the
											net amount received by us shall be equal to the full
											amount which it would have<br
												role="presentation"
											/>received had no such deduction or withholding been made
											or required to be made.<br role="presentation" />d) It
											shall be a continuing condition of your use of the
											Platform that you make timely payment of any licence fees
											due by you to us. Payment of such licence fees by you
											shall be on such additional terms as may be prescribed by
											us and notified to you from time to time. Failure to make
											payment of licence fees to us in accordance with such
											terms may result in the termination or suspension of any
											licences or permissions which we may have granted to you
											under these Terms of Use or any other agreement which we
											may have entered into with you in respect of the Platform
											or the Services.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>User Content is not moderated by us. We are not
											responsible as author, editor or publisher of any User
											Content or content provided by any other parties and made
											available via the Platform or the Website, or through any
											of the functions provided on the Services. We do not have
											control over User Content, nor do we routinely monitor
											such content and we do not make any representations or
											warranties as to the veracity or accuracy of such
											content.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>You further acknowledge and agree that any use or
											reliance by you of any User Content is entirely at your
											own risk. CognaLearn does not verify and is not in a
											position to verify any party’s rights to submit any
											content on the Services, and CognaLearn takes no
											responsibility and assumes no liability, whether direct or
											indirect or any incidental, special, indirect or
											consequential damages whatsoever, including, without
											limitation, damages for loss of profits or any other
											commercial damages or losses, for any content posted by
											you or any third party, or for your use of the same.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>We shall have the right (but not the obligation) to,
											without notice, delete, remove or disable access to any
											content (including User Content) which e deem at our sole
											discretion to be potentially defamatory of any person,
											unlawful, objectionable in any way, in violation of any
											third party rights, or for any reason whatsoever. Any
											editing or removal of any such content from the Services
											shall be without prejudice to our other rights and
											remedies available at law. For the avoidance of doubt, the
											removal of any of your User Content from the Services
											(whether by you or by CognaLearn) shall not impact any
											rights which you have granted to us in respect of your
											User Content under these Terms of Use.</span
										><span role="presentation" dir="ltr"></span>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										>5. <span>THIRD PARTY SITES AND LINKS</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>Users as well as CognaLearn may provide links to third
												party sites (e.g. articles). We have no control over
												such third party sites, and you<br
													role="presentation"
												/>acknowledge and agree that we are not responsible for
												the availability of such external sites or resources,
												and do not endorse and are not responsible or liable for
												any content, advertising, products, services or
												materials on or available through such sites or
												resources. You further acknowledge and agree that we
												shall not be responsible or liable, directly or
												indirectly, for any damage or loss caused by or in
												connection with use of or reliance on any content, goods
												or services on or available through any such site or
												resource.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>You further acknowledge that your access to and/or use
												of third party sites is entirely at your own risk, and
												that third party sites usually have their own terms and
												conditions, including privacy policies, over which we
												have no control and which will govern your rights and
												obligations with respect to the use of those sites and
												resources.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>We do not warrant that the third party sites will meet
												your requirements or that the same will not cause you
												any loss of any kind, and you agree that to the maximum
												extent permitted by law, CognaLearn shall not be liable
												for any loss or damage of any kind incurred in
												connection with your use or reliance on any content,
												information or other materials on or available through
												such third parties.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>Some software components used in the Platform may be
												offered under an open source or other license as we may
												notify you of, in which case your use of those
												components is governed by such terms to the extent only
												of any inconsistency between these Terms of Use and
												those terms.</span
											></span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										><span>6. OUR PROPERTY</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>All rights, title and interest in and to the
												intellectual property rights in and to the Platform or
												the content displayed on the Platform, including but not
												limited to the Platform’s look and feel, data,
												information, text, graphics, images, sound or video
												materials, photographs, designs, trademarks, trade names
												and content, including but not limited to User Content
												licenced to us under these Terms of Use (individually or
												collectively, the “Content”) is owned by us or by our
												third party licensors (as may be applicable). All rights
												in such Content not expressly granted to you are
												reserved.</span
											></span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										><span>7. INFRINGEMENT </span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>If you believe that your copyright material has been
												copied in a way that constitutes copyright infringement,
												please provide written notice to us with full details as
												prescribed in Part IXA of the Singapore Copyright Act,
												Cap 63, to be submitted to our designated
												representative, as follows:
												<a href="mailto:data@intedashboard.com"
													>data@intedashboard.com</a
												>.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>We will only process notices in the English language
												that comply with the Singapore Copyright Act, other
												applicable laws and these Terms of Use. Any notices that
												do not comply with the above will be rejected.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>Upon receipt of a compliant notice of infringement,
												CognaLearn may take further steps in depending on the
												nature of the alleged infringement described in the
												notice, which may include the matters described in the
												Singapore Copyright Act as applicable to network service
												providers.</span
											></span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										><span>8. TERMINATION / SUSPENSION</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>You can stop using our Services at any time. We also
												reserve the right to suspend or end the Services at any
												time at our discretion and without notice. For example,
												we may suspend or terminate your use of the Services and
												remove User Content if you’re not complying with these
												Terms of Use, or using the Services in a manner that may
												cause us legal liability, disrupt the Services, disrupt
												others’ use of the Services or, in our sole opinion,
												reason, cause harm.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>All provisions of these Terms of Use, which by their
												nature should survive, will survive termination of the
												Services.</span
											></span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										><span>9. SERVICE “AS IS”</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>COGNALEARN AND ITS PARTNERS MAKE NO WARRANTIES, EITHER
												EXPRESS OR IMPLIED, ABOUT THE SERVICES. THE SERVICES
												ARE<br role="presentation" />PROVIDED “AS IS.” IN
												ADDITION, WE DISCLAIM ANY WARRANTIES OF MERCHANTABILITY,
												FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
												NON-INFRINGEMENT. ANY CONTENT DOWNLOADED OR ACCESSED
												FROM THE SERVICES IS ACCESSED AT YOUR OWN RISK.</span
											></span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										><span>10. LIMITATION OF LIABILITY</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>YOU EXPRESSLY ABSOLVE AND RELEASE COGNALEARN FROM ANY
												CLAIM OF HARM RESULTING FROM A CAUSE BEYOND COGNALEARN’S
												CONTROL.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>YOU FURTHER AGREE THAT IN NO EVENT SHALL COGNALEARN AND
												ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES OTHER THAN
												DIRECT DAMAGES, OR ANY LOSS OF USE, DATA BUSINESS, OR
												PROFITS. THIS WILL BE REGARDLESS OF WHETHER OR NOT
												COGNALEARN OR ANY OF ITS PARTNERS HAS BEEN WARNED OF THE
												POSSIBILITY OF SUCH DAMAGES.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>ADDITIONALLY, COGNALEARN AND ITS SUPPLIERS WILL NOT BE
												LIABLE TO YOU FOR MORE THAN ANY AMOUNTS RECEIVED BY<br
													role="presentation"
												/>COGNALEARN FROM YOU AS A RESULT OF YOUR USE OF THE
												SERVICES IN THE 12 MONTHS PRECEDING YOUR CLAIM. IF YOU
												HAVE NOT PAID INDIVIDUALLY ANY AMOUNTS IN THE 12 MONTHS
												PRECEDING YOUR CLAIM, COGNALEARNS’S SOLE AND EXCLUSIVE
												LIABILITY SHALL BE NO MORE THAN US$50.</span
											></span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>COGNALEARN SHALL NOT BE LIABLE FOR THE LOSS OR FAILURE
												TO STORE USER CONTENT, AND YOU UNDERSTAND AND AGREE THAT
												BY USING THE SERVICES, YOU AGREE TO WAIVE THE RIGHT TO A
												TRIAL BY JURY AND THE RIGHT TO PARTICIPATE IN A CLASS
												ACTION RELATED TO USE OF THE SERVICES OR BREACH OF THESE
												TERMS OF USE.</span
											></span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										><span>11. CONTROLLING LAW</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>These Terms of Use shall be governed by and construed
												in accordance with laws of Singapore. Any dispute
												arising out of or in connection with these Terms of Use,
												including any question regarding its existence, validity
												or termination, shall be referred to and finally
												resolved by arbitration administered by the Singapore
												International Arbitration Centre (“SIAC”) in accordance
												with the Arbitration Rules of the Singapore
												International Arbitration Centre (“SIAC Rules”) for the
												time being in force, which rules are deemed to be
												incorporated by reference in this clause. The seat of
												the arbitration shall be Singapore. The language of the
												arbitration shall be English.</span
											></span
										>
									</li>
								</ol>
								<h2>12. <span>PRIVACY POLICY</span></h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											>It is a continuing condition of your use of the Services
											that you </span
										><span role="presentation" dir="ltr"
											>agree to the terms of our privacy policy which are also
											incorporated into</span
										><br role="presentation" /><span
											role="presentation"
											dir="ltr"
											>these Terms of Use by reference and apply your use of the
											Services</span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										>13. <span>GENERAL</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											>The illegality, invalidity or unenforceability of any
											provision of these Terms of Use under the law of any
											jurisdiction shall not affect its legality, validity or
											enforceability under the law of any other jurisdiction nor
											the legality, validity or enforceability of any other
											provision.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>No failure on the part of any party to exercise, and no
											delay on its part in exercising, any right or remedy under
											these Terms of Use will<br role="presentation" />operate
											as a waiver thereof, nor will any single or partial
											exercise of any right or remedy preclude any other or
											further exercise thereof or the exercise of any other
											right or remedy. The rights provided in these Terms of Use
											are cumulative and not exclusive of any rights or remedies
											provided by law.</span
										>
									</li>
									<li>
										<span role="presentation" dir="ltr"
											>A person who is not a party to the Terms of Use shall
											have no right to enforce or enjoy the benefit of any term
											of these Terms of Use under the Contracts (Rights of Third
											Parties) Act (Chapter 53B).</span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										>14. <span>MODIFICATIONS</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											>We may revise these Terms of Use from time to time, and
											will always post the most current version at
											<a href="/terms-of-use?hsLang=en" rel="noopener"
												>https://www.intedashboard.com/terms-of-use</a
											>. If a revision meaningfully reduces your rights, we will
											provide notice to you (by, for example, sending a message
											to the email address associated with your account). By
											continuing to use or access the Services after the
											revisions come into effect, you agree to be bound by and
											subject to the revised Terms of Use.</span
										>
									</li>
								</ol>
								<h2>
									<span role="presentation" dir="ltr"
										>15. <span>NOTICES</span></span
									>
								</h2>
								<ol>
									<li>
										<span role="presentation" dir="ltr"
											><span
												>For any notices relating to these Terms of Use, you may
												contact CognaLearn by sending an email to
												<a href="mailto:data@intedashboard.com" rel="noopener"
													>data@intedashboard.com</a
												>.</span
											></span
										>
									</li>
								</ol></span
							>
						</div>
					</div>
				</div>
			</div>
			<div
				:class="{
					'position-fixed bottom-0 start-0 w-100': helpers.layout.isMobile(),
					'position-sticky bottom-0 pt-30': !helpers.layout.isMobile(),
				}"
			>
				<div
					v-if="helpers.layout.isMobile()"
					class="custom-btn-gradient bg-gradient"
				/>
				<button type="submit" class="w-100 btn btn-primary" @click="agree">
					I Agree
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, inject } from "vue";

export default {
	emits: ["agreed"],
	setup(props, context) {
		const $api = inject("$api");
		const helpers = inject("helpers");

		// const thisData = ref(null);

		// $api.client
		// 	.get("https://www.intedashboard.com/terms-of-use")
		// 	.then(function (response) {
		// 		thisData.value = response.data;
		// 	});

		const agree = () => {
			context.emit("agreed");
		};
		return {
			helpers,
			// thisData,
			agree,
		};
	},
};
</script>

<style lang="scss">
.terms-of-use {
	p {
		white-space: pre-line;

		&:not(.fontBold) {
			word-break: break-word;
			hyphens: auto;
		}
	}

	.fontBold {
		font-weight: bold;
	}

	.marginRight10px {
		margin-right: 10px;
	}

	.number-tag {
		margin-right: 10px;
		font-weight: bold;
	}
}
</style>
